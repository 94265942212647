import React, {useState, useEffect} from 'react';
import queryString from 'query-string';
import Page from '../page/Page';

import './SuccessPage.scss';

export default function SuccessPage() {

    const linkParams = queryString.parse(window.location.search);

    
    return (
        <div className={'dg-success'}>
            <img src={'/success_1.svg'}/>
            <h1>Thank you for your {linkParams.amount && <span className={'amount'}>£{linkParams.amount}</span>} donation</h1>
            <p>Your donation was processed successfully. Thank you for your contribution.
            </p>

            <div className={'dg-logo'}>
                <p>Powered by</p>
                <a href={'https://dugood.co.uk'}><img src={'/logo-w.svg'}/></a>
            </div>
        </div>
    );
}