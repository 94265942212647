import React, { useState, useEffect } from 'react';
import Give from './common/Give';
import ErrorPage from './common/components/errorPage/ErrorPage';
import SuccessPage from './common/components/successPage/SuccessPage';
import Bootstrap from 'bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './App.css';
import './styles/variables.scss';


function App() {

  return (
      <Router>
        <Switch>
          <Route path={"/error"}>
            <ErrorPage />
          </Route>
          <Route path={"/success"}>
            <SuccessPage />
          </Route>
          <Route>
            <Give />
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
