import React from 'react';
import './Footer.scss';
import Container from '../container/Container';
import {Row, Col, Nav} from 'react-bootstrap';

export default function Footer(orgInfo) {
    return (
      <div className="dg-footer">
          <Row>
                <Col className={'cookies'}>
                    <div >
                        <p>By using Dugood Give, you are consenting to our use of cookies.</p>
                    </div>
                </Col>
          </Row>
            <Row className={'logos'}>
                <Col sm={6} xs={6} md={2}>
                    <div className={"givesafe"}>
                        <div className={"org"}><img src={"/gs-check.svg"} alt={"Accredited"}/> <p>{orgInfo.orgInfo.givesafe}</p></div>
                    </div>
                    
                </Col>
                
                <Col sm={6} xs={6} md={2} className={"copyright"}>
                    <img src={"/logo-c.svg"} alt={"Powered by Dugood"}/>
                </Col>
                </Row>
            
      </div>
    );
}