import React, { useState, useEffect } from "react";
import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import firebase, { db, functions } from '../../../firebase.js';

const {Loader} = require('@dugood/dds');

export default function CheckoutForm(props) {
    
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    const cardStyle = {
      style: {
        base: {
          color: "#32325d",
          fontFamily:'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontSmoothing: "antialiased",
          fontSize: "15px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
    };

    
    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };
    
    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(props.props.secret, {
          payment_method: {
            card: elements.getElement(CardElement),
          }
        });
    
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          setProcessing(false);
        } else {
          setError(null);
          handleSuccessfulDonation(payload.paymentIntent);
          setProcessing(false);
        }
    };

    async function handleSuccessfulDonation(paymentIntent) {
      var now = new Date;

      setSucceeded(true);

      // Record the new donation

      let data = {
        orgInfo: props.props.orgInfo,
        donation: props.props.donation,
        amountSelected: props.props.amountSelected,
        feeCovered: props.props.feeCovered,
        donationId: props.props.donationId,
        giftaid: props.props.giftaid,
        qr: props.props.qr,
        paymentIntent: paymentIntent
      };

      var recordDonation = firebase.functions().httpsCallable('giveRecordDonation');
      var result = await recordDonation(data);

      window.location.href = `/success?amount=${props.props.donation}`;

    }

    

    useEffect(async () => {

      async function prepareApplePay() {
        var createApplePayUrl = functions.httpsCallable('giveCreateApplePayUrl');
  
        await createApplePayUrl({stripe: props.props.orgInfo.stripe});

        return;
      }

      if(stripe && props.props.secret) {

        prepareApplePay();

        const pr = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: `${props.props.orgInfo.orgName} via Dugood`,
            amount: (props.props.donation * 100),
          },
          requestPayerName: true,
        });
    
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
    
      }
    }, [stripe, props.props.secret])

    // Code for mobile wallet button

    if(paymentRequest) {
      paymentRequest.on('paymentmethod', async (ev) => {
      
      const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
        props.props.secret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );
    
      if (confirmError) {
        setError(`Payment failed ${confirmError}`);
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.

        ev.complete('success');

        // Check if the PaymentIntent requires any actions and if so let Stripe.js handle the flow.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await stripe.confirmCardPayment(props.props.secret);
          if (error) {
            setError(`Payment failed ${confirmError}`);
          } else {
            handleSuccessfulDonation(paymentIntent);
          }
        } else {
          handleSuccessfulDonation(paymentIntent);
        }
      }
    });}

    return (
      <>
        <Loader
          title={'Processing your donation...'}
          visible={processing || succeeded}
        />
        <form id="payment-form" onSubmit={handleSubmit}>

            {paymentRequest && <div className={'dg-input payment'}>
              <div className={'payment-button'}>
                <div className={'method-blurb'}>
                  Tap here to donate in seconds using
                </div>
                <PaymentRequestButtonElement options={{paymentRequest}} />
              </div>
            </div>}

            <div className={'dg-input payment'}>
              <div className={'method-blurb'}>
                  {paymentRequest ? 'Or enter your card details' : 'Enter your card details'}
              </div>

              {error && <div className={'alert alert-danger'}>
                {error}
              </div>}

              <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
              <button
                  disabled={processing || disabled || succeeded}
                  id="submit"
              >
                  <span id="button-text">
                  {processing ? (
                    <img src={'1484.gif'} height={'30px'} />
                  ) : (
                      `Donate £${props.props.donation}`
                  )}
                  </span>
              </button>
            </div>
            
            
        </form>
        
      </>
    );
}