import React from "react";
import { useFormik, validateYupSchema } from "formik";
import { FormControl, FormLabel, FormGroup, Form, Alert, Row, Col, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import './GiftAidForm.scss';

const validate = values => {
    const errors = {};

    if (!values.title) {
        errors.title = 'Please provide your title.';
    }

    if (!values.fName) {
        errors.fName = 'Please provide your first name.';
    } else if (values.fName.length > 35) {
        errors.fName = 'Maximum 35 characters.';
    }

    if (!values.lName) {
        errors.lName = 'Please provide your last name.';
    } else if (values.lName.length > 35) {
        errors.lName = 'Maximum 35 characters.';
    }

    if (!values.houseNameNumber) {
        errors.houseNameNumber = 'Please provide your house name/number.';
    }

    if (!values.postcode) {
        errors.postcode = 'Please provide your postcode.';
    } else if (!/^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/i.test(values.postcode)) {
        errors.postcode = 'Please provide a valid postcode.';
    }
    return errors;
}

export default function GiftAidForm(props) {

    
    const formik = useFormik({
        initialValues: {
            title: 'Mr',
            fName: '',
            lName: '',
            houseNameNumber: '',
            postcode: ''
        },
        validate,
        onSubmit: values => {
            props.handleGiftAid(values)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={'declaration'}>
            I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on all my donations it is my responsibility to pay any difference.
            </div>
            <Row className={'dg-form name'}>
                <FormGroup>
                    <label htmlFor="title">Title</label>
                    <select
                        id="title"
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        className={formik.errors.title && 'invalid'}
                    >
                        <option>Mr</option>
                        <option>Mrs</option>
                        <option>Miss</option>
                        <option>Ms</option>
                        <option>Mx</option>
                        <option>Dr</option>
                    </select>
                    {formik.errors.title ? <div className={'validation'}>{formik.errors.title}</div> : null}
                </FormGroup>
                <FormGroup>
                    <label htmlFor="fName">First name</label>
                    <input
                        id="fName"
                        name="fName"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.fName}
                        className={formik.errors.fName && 'invalid'}
                    />
                    {formik.errors.fName ? <div className={'validation'}>{formik.errors.fName}</div> : null}
                </FormGroup>
                <FormGroup>
                    <label htmlFor="lName">Last name</label>
                    <input
                        id="lName"
                        name="lName"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.lName}
                        className={formik.errors.lName && 'invalid'}
                    />
                    {formik.errors.lName ? <div className={'validation'}>{formik.errors.lName}</div> : null}
                </FormGroup>
            </Row>
            <Row className={'dg-form address1'}>
                    <FormGroup>
                        <label htmlFor="houseNameNumber">House name/number</label>
                        <input
                            id="houseNameNumber"
                            name="houseNameNumber"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.houseNameNumber}
                            className={formik.errors.houseNameNumber && 'invalid'}
                        />
                        {formik.errors.houseNameNumber ? <div className={'validation'}>{formik.errors.houseNameNumber}</div> : null}
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="postcode">Postcode</label>
                        <input
                            id="postcode"
                            name="postcode"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.postcode}
                            className={formik.errors.postcode && 'invalid'}
                        />
                        {formik.errors.postcode ? <div className={'validation'}>{formik.errors.postcode}</div> : null}
                    </FormGroup>
            </Row>
            <button type="submit">Continue</button>
        </form>
    );
};