import React from 'react';
import './Page.scss';

export default function Page({children, colour}) {

    if(!colour) colour = '#ff8f1c';

    return (
      <div className="dg-page" style={{backgroundColor: colour}}>
          {children}
      </div>
    );
}