import React, {useState, useEffect} from 'react';
import Page from '../page/Page';

import './ErrorPage.scss';

export default function ErrorPage() {

    
    return (
    <Page>
        <div className={'dg-error'}>
            <h1>Something went wrong</h1>
            <p>There was a problem with the link you followed or the QR code you scanned.
                <br/>
                The error has been recorded and will be looked at soon.
            </p>
        </div>
        
    </Page>
    );
}