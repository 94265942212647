import React, {useState, useEffect} from 'react';
import Page from '../common/components/page/Page';
import Container from '../common/components/container/Container';
import Header from './components/header/Header.js';
import Footer from './components/footer/Footer.js';
import PageTitle from '../common/components/pageTitle/PageTitle';
import StartButton from '../common/components/startButton/StartButton';
import Payment from '../common/components/payment/Payment';
import GiftAidForm from '../common/components/giftAidForm/GiftAidForm';

import firebase, { functions, db } from '../firebase.js';
import queryString from 'query-string';
import ErrorMessageHandler from '../common/components/errorMessageHandler/ErrorMessageHandler';
import { FormControl, FormLabel, FormGroup, Form, Alert, Row, Col, TabContainer, TabContent, TabPane } from 'react-bootstrap';

import './Give.scss';
import { validateYupSchema } from 'formik';

export default function Give(user, stripe) {

    const [state, setState] = useState({
        donation: null,
        amountSelected: null,
        donationId: null,
        currentTab: 1,
        orgInfo: null,
        giftAid: {
            title:null,
            fName:null,
            lName:null,
            houseNameNumber:null,
            postcode:null,
        },
        secret: false,
        useCustom: false,
        qr: null,
        amountPreset: false,
        gaParam: 'unset',
        feeCovered: false,
    })

    var clientSecret = null;

    useEffect(() => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        var orgId = urlParams.get('org'); // Required
        var qrId = urlParams.get('qr'); // Required. Use 000 for App
        var setAmount = urlParams.get('amount'); // Optional
        var gaParam = urlParams.get('ga');

        if(orgId == undefined) {
            console.log('noorg');
            window.location.href = '/error';
        }

        if(!qrId) {
            console.log('badurl');
            window.location.href = '/error';
        }

        var docRef = db.collection("organisations").doc(orgId);

        docRef.get().then(async function(doc) {
            if (doc.exists) {

                //Check stripe

                if(!doc.data().stripe) {
                    console.log('nostripe');
                    window.location.href = '/error';
                };

                // Fetch PaymentIntent if preset amount else just set state

                setState({...state, 
                    orgId: doc.id, 
                    orgInfo: doc.data(),
                    qr: qrId,
                    gaParam: gaParam ? gaParam : 'unset',
                });

                /*if(setAmount) {

                    let response = await getPaymentSecret(Number.parseFloat(setAmount).toFixed(2), doc.data().stripe, doc.id, qrId);
                    
                    console.log(response);

                    setState({...state, 
                        orgId: doc.id, 
                        orgInfo: doc.data(),
                        qr: qrId,
                        donation: Number.parseFloat(setAmount).toFixed(2),
                        secret: response.secret,
                        donationId: response.id,
                        currentTab: doc.data().giftaid && gaParam !== 'false' ? 2 : 4,
                        amountPreset: true,
                        gaParam: gaParam ? gaParam : 'unset'
                    });
                } else {
                    setState({...state, 
                        orgId: doc.id, 
                        orgInfo: doc.data(),
                        qr: qrId,
                        gaParam: gaParam ? gaParam : 'unset',
                    });
                }*/
                
            } else {
                // doc.data() will be undefined in this case
                console.log("Fatal error: couldn't find organisation");
            }
        }).catch(function(error) {
            console.log(error);
            window.location.href = '/error';
        });

    }, []);

    async function handleClickAmount(e, amount) {
        setState({...state, 
            donation: parseFloat(amount),
            amountSelected: parseFloat(amount),
            currentTab: 2
        });
        
    }

    async function handleClickCoverFee(e) {

        let donation = state.donation;

        donation = donation + Math.ceil(((donation * (0.014 + 0.015))+0.2)*100) / 100;

        e.target.innerHTML = `<img src=\'loader-2.gif'\ height=\'30px'\ style='mix-blend-mode: difference; filter: invert(100%) brightness(1)'/>`;

        let response = await getPaymentSecret(true, donation, state.orgInfo.stripe);

        setState({
            ...state,
            donation: donation.toFixed(2),
            donationId: response.id,
            secret: response.secret,
            feeCovered: true,
            currentTab: (state.orgInfo.giftaid && state.gaParam !== 'false') ? 3 : 5,
        });
    }

    async function handleClickNoCoverFee(e) {
        let donation = state.donation;

        e.target.innerHTML = `<img src=\'loader-2.gif'\ height=\'30px'\ style='mix-blend-mode: difference; filter: invert(100%) brightness(1)'/>`;

        let response = await getPaymentSecret(false, donation, state.orgInfo.stripe);

        setState({
            ...state,
            donation: donation,
            donationId: response.id,
            secret: response.secret,
            feeCovered: false,
            currentTab: (state.orgInfo.giftaid && state.gaParam !== 'false') ? 3 : 5,
        });
    }

    async function getPaymentSecret(feeCovered, amount, stripe, orgId, qr) {

        console.log(state.feeCovered);

        var intentInfo = {
            amount: amount,
            amountSelected: state.amountSelected,
            account: stripe,
            orgInfo: state.orgInfo,
            orgId: orgId ? orgId : state.orgInfo.orgId,
            qr: qr ? qr : state.qr,
            feeCovered: feeCovered,
        };

        var getPaymentIntent = firebase.functions().httpsCallable('giveCreatePaymentIntent');
        var result = await getPaymentIntent(intentInfo);
        return result.data;
    }

    function getFeeAmount(donation) {
        let feeAmount = Math.ceil(((donation * (0.014 + 0.015))+0.2)*100) / 100;

        if(feeAmount > 1) {
            return `£${feeAmount.toFixed(2)}`
        } else {
            return `${feeAmount * 100}p`
        }

    }

    function checkGiftAid(values) {
        setState({
            ...state,
            currentTab: 5,
            giftAid: values
        })
    }

    return (
        <>
        {state.orgInfo
        ?   <Page colour={state.orgInfo.brand}>
            <div className={'title'}>
                    <h3>Donate <b>{state.amountPreset && `£${state.donation} `}</b>to support</h3>
                    <h1 className={'charity-name'}>{state.orgInfo.orgName}</h1>
                </div>
            <Row className={'dg-give'}>
                
                <div className={'right'}>
                    <TabContainer activeKey={state.currentTab} className={'tabs'}>
                        <TabContent>
                            <TabPane eventKey={1} id={'one'}>
                                <h5>Step 1 of {state.orgInfo.giftaid && state.gaParam !== 'false' ? '4' : '3'}</h5>
                                <h4>How much do you want to give?</h4>
                                {state.useCustom
                                ? <div className={`amounts custom`}>
                                    <p>Whole numbers only. No pence, please</p>
                                    <span>£</span><input type={'number'}
                                                        min={1}
                                                        onChange={(e) => {setState({...state, donation: parseInt(e.target.value)})}}
                                                        value={state.donation}
                                                        required
                                                    />
                                    <button onClick={(e) => {handleClickAmount(e, state.donation)}} disabled={state.donation == null}>Give £{state.donation}</button>
                                </div>
                                : <div className={`amounts`}>
                                    <button 
                                        onClick={(e) => {handleClickAmount(e, 2)}}
                                        className={`amount ${state.donation == 2 && 'selected'}`}
                                        autoFocus={state.donation == 2}
                                    >£2</button>
                                    <button
                                        onClick={(e) => {handleClickAmount(e, 5)}}
                                        className={`amount ${state.donation == 5 && 'selected'}`}
                                        autoFocus={state.donation == 5}
                                    >£5</button>
                                    <button
                                        onClick={(e) => {handleClickAmount(e, 10)}}
                                        className={`amount ${state.donation == 10 && 'selected'}`}
                                        autoFocus={state.donation == 10}
                                    >£10</button>
                                    <button
                                        onClick={(e) => {handleClickAmount(e, 15)}}
                                        className={`amount ${state.donation == 15 && 'selected'}`}
                                        autoFocus={state.donation == 15}
                                    >£15</button>
                                    <button
                                        onClick={(e) => {handleClickAmount(e, 25)}}
                                        className={`amount ${state.donation == 25 && 'selected'}`}
                                        autoFocus={state.donation == 25}
                                    >£25</button>
                                    <button className={'more'} onClick={() => setState({...state, useCustom: true})}>
                                        ...
                                    </button>
                                </div>
                                }
                                
                                <p className={'donate-with'}>
                                    <img src={'./methods.svg'}/>
                                </p>
                            </TabPane>

                            <TabPane eventKey={2} id={'two'}>
                                <h5>Step 2 of {state.orgInfo.giftaid && state.gaParam !== 'false' ? '4' : '3'}</h5>

                                <h4>Add {getFeeAmount(state.amountSelected)} to cover our fees?</h4>

                                <p>{state.orgInfo.orgName} pays a fee on every donation they recieve.</p>
                                <p>You can add an extra <b>{getFeeAmount(state.amountSelected)}</b> to your donation so {state.orgInfo.orgName} doesn't have to pay anything.</p>

                                <button onClick={(e) => {handleClickCoverFee(e)}}>
                                    Add {getFeeAmount(state.amountSelected)} to my donation
                                </button>

                                <button onClick={(e) => {handleClickNoCoverFee(e)}} className={'muted'}>
                                    No thanks
                                </button>
                            </TabPane>

                            <TabPane eventKey={3} id={'three'}>
                                {state.amountPreset
                                ? <h5>Step 2 of 3</h5>
                                : <h5>Step 3 of 4</h5>}
                                <h4>Add Gift Aid to your £{state.donation} donation</h4>
                                <p>Are you a UK taxpayer? Turn your £{state.donation} donation into <b>£{(Number(state.donation) + Number(state.donation * 0.25)).toFixed(2)}</b> at no extra cost to you with Gift Aid.</p>

                                <button onClick={() => setState({...state, currentTab: 4})}>Add Gift Aid</button>
                                <button onClick={() => setState({...state, currentTab: 5})} className={'muted'}>No thanks</button>
                            </TabPane>

                            <TabPane eventKey={4} className={'wider'} id={'four'}>
                                <h5>Step 3 of 4</h5>
                                <h4>Add Gift Aid to your £{state.donation} donation</h4>
                                <p>Please provide your details so your Gift Aid can be processed. This information won't be used for any other purposes.</p>

                                <GiftAidForm handleGiftAid={checkGiftAid}/>
                                <button onClick={() => setState({...state, currentTab: 5})} className={'muted'} style={{marginTop: '10px'}}>Skip Gift Aid</button>
                            </TabPane>
                            <TabPane eventKey={5} id={'five'}>
                                <h5>Step {state.amountPreset ? state.orgInfo.giftaid && state.gaParam !== 'false' ? '2 of 2' : '1 of 1' : state.orgInfo.giftaid && state.gaParam !== 'false' ? '3 of 3' : '2 of 2'}</h5>
                                <h4>Make your donation of £{state.donation}</h4>

                                
                                <Payment donation={state.donation} 
                                    orgInfo={state.orgInfo} 
                                    orgId={state.orgId}
                                    secret={state.secret} 
                                    donationId={state.donationId}
                                    amountSelected={state.amountSelected}
                                    giftaid={state.giftAid} 
                                    qr={state.qr}
                                    feeCovered={state.feeCovered}
                                />
                                
                                
                                
                            </TabPane>
                        </TabContent>
                    </TabContainer>
                    
                
                </div>
                
            </Row>
            <Footer orgInfo={state.orgInfo}/>
            </Page>
        :   <Page><div className={'dg-loading'}>
                <p>Loading...</p>
            </div></Page>}
        </>
    );
}