import React, {useEffect, useState} from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm.js';



export default function Payment(props) {

    const [stripePromise,setStripePromise] = useState(null)

    useEffect(() => {
        let promise = loadStripe("pk_live_OSEzhVpIec42JuVAVBxmUCS6003CgSjKzG");

        setStripePromise(promise)
    },[])

    // pk_test_4N0aNGY2xXM3YmjI3JAet3Go00qiy3KON5
    // pk_live_OSEzhVpIec42JuVAVBxmUCS6003CgSjKzG

    return (
        <>
        {stripePromise !== null && <Elements stripe={stripePromise}>
                <CheckoutForm props={props}/>
            </Elements>
        }
        </>
    );
};